
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AuthenticationPasswordRecoveryIndex} from "@/models/authentication/password-recovery";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElContainer,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
} from "element-plus";

// Services
import {postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElContainer,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
  },
})
export default class AuthenticationPasswordRecoveryIndexVue extends Vue {
  isLoading = false;

  formData: AuthenticationPasswordRecoveryIndex = new AuthenticationPasswordRecoveryIndex();
  formDataRules = {
    email: [
      {
        required: true,
        type: "email",
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
  };

  get translation(): any {
    return getTranslation([
      "continue",
      "email",
      "emailPlaceholder",
      "passwordRecovery",
      "return",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/authentication/password-recovery",
      formData: this.formData,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$router.push({
          name: "AuthenticationLoginIndexVue",
        });
      }
    });
    this.isLoading = false;
  }
}
