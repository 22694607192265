export interface AuthenticationPasswordRecoveryIndex {
  email: string;
}

export class AuthenticationPasswordRecoveryIndex implements AuthenticationPasswordRecoveryIndex {
  email: string;

  constructor() {
    this.email = "";
  }
}