import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb4fd4e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "authentication-password-recovery" }
const _hoisted_2 = { class: "section-default" }
const _hoisted_3 = { class: "authentication-password-recovery__headline" }
const _hoisted_4 = { class: "authentication-password-recovery__headline-title" }
const _hoisted_5 = { class: "authentication-password-recovery__form" }
const _hoisted_6 = { class: "form--default" }
const _hoisted_7 = { class: "authentication-password-recovery__navigation" }
const _hoisted_8 = { class: "authentication-password-recovery__navigation-return" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 16,
            type: "flex",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 12,
                md: 10,
                lg: 8,
                xl: 8
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.passwordRecovery), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_el_form, {
                        model: _ctx.formData,
                        rules: _ctx.formDataRules,
                        ref: "formComponent",
                        onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: _ctx.translation.email,
                            prop: "email"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                type: "email",
                                placeholder: _ctx.translation.emailPlaceholder,
                                modelValue: _ctx.formData.email,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.email) = $event))
                              }, null, 8, ["placeholder", "modelValue"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_el_form_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                type: "primary",
                                class: "el-button--primary el-button--block",
                                "native-type": "submit",
                                loading: _ctx.isLoading
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translation.continue), 1)
                                ]),
                                _: 1
                              }, 8, ["loading"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["model", "rules", "onSubmit"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_router_link, { to: { name: 'HomeIndexVue' } }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.return), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}